import styled from 'styled-components';

import {colors} from '../views.constants';

const Bar = styled.div`
  position: fixed;
  z-index: 1;
  height: 50px;
  width: 100%;
  background: ${colors.RACING_GREEN};
`;

const BarWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 5px auto 0 auto;
  display: flex;
  align-items: center;
  color: white;
  h1 {
    font-size: 16px;
    margin-left: 10px;
    font-weight: normal;
  }
`;

export {
  Bar,
  BarWrapper,
};
