// Copyright (C) 2021, Sensative AB (www.sensative.com)

import { REPORT_TYPES } from '../settings.constants';
import { SETTINGS } from './settings';

/*
const defaultConfirmedReports = [
  'doorAlarm',
  'tamperAlarm',
  'averageTempAlarm',
  'floodAlarm',
  'oilAlarm',
//  'userSwitch1Alarm', Not included
//  'userButton1Alarm', Not included
  'closeProximityAlarm',
  'disinfectAlarm',
];
*/


const customprofile = {
  name: 'Custom Profile (Advanced)',
  description: 'Custom profile, add reports and settings manually in next steps',
  reports: [],
  confirmedReports: [],
  settings : [
  ],
  advanced : true,
  skipProfileSummary : true,
};

const comfort = {
  temphumiditylight_interval : {
    name: 'Periodic - Temperature, Humidity, and Light',
    description: 'Report temperature, humidity and light conditions at a set interval',
    reports: ['averageTempReport'],
    combinedReportsOne: ['ambientLightReport', 'humidityReport', 'averageTempReport'],
    confirmedReports: [],
    settings: [ 
      { setting: SETTINGS.basePollInterval, value: 1.5},
      { setting: SETTINGS.averageTempTime, value: 60 } 
    ],
  },

  temphumidity : {
    name: 'Temperature and Humidity',
    description: 'Report temperature and humidity conditions as either change',
    reports: ['tempReport', 'humidityReport'],
    confirmedReports: [],
    settings: [
        { setting: SETTINGS.tempPollInterval, value: 2 },  // Temp poll interval
        { setting: SETTINGS.tempSendImmediatelyThreshold, value: 1}, // Temperature threshold 
        { setting: SETTINGS.humPollInterval,  value: 2 },  // Hum poll interval
        { setting: SETTINGS.humThresholdLevel, value: 4  },  // Humidity threshold
        { setting: SETTINGS.basePollInterval, value: 1.5},
      ],
  },

  temphumiditylight : {
    name: 'Temperature, Humidity, and Light',
    description: 'Report temperature, humidity and light conditions as they each change',
    reports: ['ambientLightReport', 'tempReport', 'humidityReport'],
    confirmedReports: [],
    settings: [
      { setting: SETTINGS.tempPollInterval, value: 10},
      { setting: SETTINGS.tempSendImmediatelyThreshold, value: 1}, // Temperature threshold 
      { setting: SETTINGS.humPollInterval, value: 10},
      { setting: SETTINGS.humThresholdLevel, value: 4  },  // Humidity threshold
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },

  customprofile : {...customprofile, confirmedReports:['tempAlarm', 'averageTempAlarm']},
};

const guard = {
  doorreport : {
    name: 'Door Report',
    description: 'Send a report (unconfirmed) when door/window is opened or closed',
    reports: ['doorReport', 'tamperReport'],
    confirmedReports: [],
    settings: [
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  dooralarm : {
    name: 'Door/Window Alarm',
    description: 'Send an alarm when door/window is opened or closed',
    reports: ['doorAlarm'],
    confirmedReports: ['doorAlarm'],
    settings: [
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  doorcounter : {
    name: 'Door Counter',
    description: 'Report how many times the door was opened per interval',
    reports: ['doorCountReport'],
    confirmedReports: [],
    settings: [
      { setting : SETTINGS.doorCountInterval, value : 60 },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  opendoor : {
    name: 'Open Door Only Report',
    description: 'Made for situations where radio signals can\'t get through when the door\'s shut. When the door\'s open, Strips will send a report every 5 minutes for the first 20 hours, then switch to once an hour. This feature only works with the newer Strips firmware (from February 2024 onwards, R9 for EU and R7 for US), letting you know how long the door\'s been open at set times. Contact Sensative technical support for more information.',
    reports: ['doorCountReport'],
    confirmedReports: [],
    settings: [
      { setting: SETTINGS.doorCountInterval, value : 66560.085},
      { setting: SETTINGS.sensorConfiguration, value: 24},
      { setting: SETTINGS.basePollInterval, value: 1.5},
      { setting: SETTINGS.linkcheckInterval, value: 0},
    ],
  },
  customprofile : {...customprofile, confirmedReports:['doorAlarm', 'tamperAlarm', 'tempAlarm']},
};

/*
const guard_lite = guard;
*/

const drip = {
  leakage : {
    name: 'Liquid Leak Alarm',
    description: 'Report if liquid is detected',
    reports: ['floodAlarm'],
    confirmedReports: ['floodAlarm'],
    settings: [
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },

  leakage_and_temp : {
    name: 'Liquid Leak and Frost Alarm',
    description: 'Report if liquid is detected or if temperature nears freezing',
    reports: ['floodAlarm', 'tempAlarm'],
    confirmedReports: ['floodAlarm', 'tempAlarm'],
    settings: [
      { setting: SETTINGS.tempLowAlarmLevel, value : 4 },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },

  average_temp : {
    name: 'Average Temperature',
    description: 'Report the average temperature on a set interval',
    reports: ['averageTempReport'],
    confirmedReports: [],
    settings: [
      { setting: SETTINGS.averageTempTime, value: 60 },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },

  cold_storage : {
    name: 'Cold Storage',
    description: 'Report if average temperature exceeds a set threshold or if current temperature exceeds a higher threshold.',
    reports: ['tempAlarm', 'averageTempAlarm'],
    confirmedReports: ['tempAlarm', 'averageTempAlarm'],
    settings: [
      {setting: SETTINGS.tempHighAlarmLevel, value: -10},
      {setting: SETTINGS.averageTempAlarmHighLevel, value: -15},
      {setting: SETTINGS.averageTempTime, value: 60},
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  customprofile : {...customprofile, confirmedReports:['tempAlarm', 'averageTempAlarm', 'floodAlarm']},
};

const drip_oil = {
  waterAndOil : {
    name: 'Liquid and Oil Leak Alarm',
    description: `Report if lidquid or oil is detected. The device MUST be calibrated once mounted and with no oil present.
    Calibrate by sending the downlink 030030 (hex)/ AwAw (base64) to port 11.`,
    reports: ['floodAlarm', 'oilAlarm'],
    confirmedReports: ['floodAlarm', 'oilAlarm'],
    settings: [
      { setting: SETTINGS.oilFilterFactor, value: 10 },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  waterAndOilAndTemp : {
    name: 'Liquid, Oil Leak, and Temperature Alarm',
    description: `Report if liquid or oil is detected or temperature limits exceeded. The device MUST be calibrated once mounted and with no oil present.
    Calibrate by sending the downlink 030030 (hex)/ AwAw (base64) to port 11.`,
    reports: ['floodAlarm', 'oilAlarm', 'tempAlarm'],
    confirmedReports: ['floodAlarm', 'oilAlarm', 'tempAlarm'],
    settings: [
      { setting: SETTINGS.oilFilterFactor, value: 10 },
      { setting: SETTINGS.tempLowAlarmLevel, value: -10 },
      { setting: SETTINGS.tempHighAlarmLevel, value: 50 },
      { setting: SETTINGS.tempPollInterval, value: 1 },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  customprofile : {...customprofile, confirmedReports:['tempAlarm', 'averageTempAlarm', 'floodAlarm', 'oilAlarm']},
};

/* Removed after Morgan review, 2021-02-19
   Added after Morgan review, 2021-06-08 */
const multisensor = {
  /* leakage:drip.leakage,
  leakage_and_temp:drip.leakage_and_temp,
  temp: drip.average_temp,
  cold_storage:drip.cold_storage,
  dooralarm:guard.dooralarm,
  doorcounter:guard.doorcounter, */
  customprofile : {...customprofile, confirmedReports:['doorAlarm', 'tamperAlarm', 'tempAlarm', 'averageTempAlarm', 'floodAlarm', 'oilAlarm','userSwitchAlarm']},
};

const presence = {
  office: {
    name: "Office",
    description: "For a normal office setting. Long timeout not to trigger during breaks and shorter meetings.",
    reports: ['presenceReport'],
    confirmedReports: [],
    settings: [
      { setting: SETTINGS.presencePollInterval, value: 60 },
      { setting: SETTINGS.presenceTimeout, value: 60    },
      { setting: SETTINGS.presenceThreshold, value: 50    },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  public: {
    name: "Public",
    description: "For restaurants, cafés and reception areas. Shorter timeout to better reflect current situation. Still allowing for short absences.",
    reports: ['presenceReport'],
    confirmedReports: [],
    settings: [
      { setting: SETTINGS.presencePollInterval, value: 60 },
      { setting: SETTINGS.presenceTimeout, value: 20      },
      { setting: SETTINGS.presenceThreshold, value: 50    },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  confirmed: {
    name: "Office (Confirmed)",
    description: `For a normal office setting. Long timeout not to trigger during breaks and shorter meetings. Sending messages as Confirmed. 
                  Applicable to devices with software revision R12 or higher (produced after June 2022)`,
    reports: ['presenceReport'],
    confirmedReports: ['presenceReport'],
    settings: [
      { setting: SETTINGS.presencePollInterval, value: 60 },
      { setting: SETTINGS.presenceTimeout, value: 60    },
      { setting: SETTINGS.presenceThreshold, value: 50    },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  objectdetect: {
    name: "Object Detection Close",
    description: "Alarm if an object is or is not in close proximity (but not pressed against) the sensor. Can be used for monitoring removal of safety equipment or art",
    reports: ['closeProximityAlarm'],
    confirmedReports: ['closeProximityAlarm'],
    settings: [
      { setting: SETTINGS.irProximityPollInterval, value: 30 },
      { setting: SETTINGS.presencePollInterval, value: 30 },
      { setting: SETTINGS.presenceThreshold, value: 30 },
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  highproximityalarm: {
    name: "Object Detection Far",
    description: "Alarm if an object is or is not in far proximity (but not pressed against) the sensor. Can be used for monitoring removal of safety equipment, art or certain types of Presence detection",
    reports: ['closeProximityAlarm'],
    confirmedReports: ['closeProximityAlarm'],
    settings: [
      { setting: SETTINGS.irProximityPollInterval, value: 60 },
      { setting: SETTINGS.presencePollInterval, value: 60 },
      { setting: SETTINGS.presenceThreshold, value: 500 },
      { setting: SETTINGS.basePollInterval, value: 1.5},
      { setting: SETTINGS.sensorConfiguration, value: 2},
    ],
  },
  disinfectAlarm: {
    name: "Disinfect Status Alarm",
    description: "Indicate the cleaning status of a desk. Requires +Switch accessory. See manual for details.",
    reports: ['disinfectAlarm'],
    confirmedReports: ['disinfectAlarm'],
    settings: [
      { setting: SETTINGS.presencePollInterval, value: 30 },
      { setting: SETTINGS.presenceTimeout, value: 20      },
      { setting: SETTINGS.presenceThreshold, value: 50    },
      { setting: SETTINGS.ledLevel, value: 1},
      { setting: SETTINGS.basePollInterval, value: 1.5},
    ],
  },
  customprofile : {...customprofile, confirmedReports:['disinfectAlarm', 'userSwitchAlarm']},
}

export default {
  /* Removed in review with Morgan 2021-02-19:  multisensor, */
  presence,
  comfort,
  guard,
  drip,
  drip_oil,
  multisensor,
};
